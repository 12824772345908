import { Endpoints, fetchData, strapiOptions } from '@/libs/api/data-fetcher';
import { TSeoType } from './seo';
import { TAdComponent } from '@/features/ad/model';
import { RawCarousel } from '@/features/carousel/model';
import { RawIframeBlock } from '@/features/iframe-block/model';
import { RawCarouselPromo } from '@/features/carouselPromo/model';
import { THorizontalPills } from '@/components/HorizontalPills/model';
import { TCarouselRecentlyPlayed } from '@/features/carouselRecentlyPlayed/model';
import qs from 'qs';

export const fetchModularPagesByDomain = async (domain: string): Promise<Array<IModularPageBase>> => {
	const query = qs.stringify({
		filters: { arenas: { domain: { $eq: domain } } },
		populate: {
			modular_pages: {
				fields: ['id', 'slug', 'name'],
			},
		},
	});
	const url = Endpoints.getModularPagesByQuery(query),
		response = await fetchData(url, strapiOptions),
		modularPages: IModularPageBase[] | null = response.data?.map((g: any) => g.modular_pages)?.flat();
	if (!modularPages) {
		return [];
	}

	const result = modularPages.filter(
		(page, i, arr) => arr.findIndex((pageToCompare) => pageToCompare.id === page.id) === i,
	);
	return result;
};

export interface IModularPageBase {
	id: number;
	name: string;
	slug: string;
}

export type Ad = { dimensions: string; ad_id: string };

export interface IModularPage extends IModularPageBase {
	customScripts: string | null;
	createdAt: Date;
	updatedAt: Date;
	publishedAt: Date;
	seo: TSeoType | null;
	content: Array<
		TAdComponent | RawCarousel | RawCarouselPromo | RawIframeBlock | THorizontalPills | TCarouselRecentlyPlayed
	>;
	analyticId: string | null;
	bottomPadding: boolean | null;
}

export const initModularPage: IModularPage = {
	id: 0,
	name: '',
	slug: '',
	customScripts: '',
	createdAt: new Date(),
	updatedAt: new Date(),
	publishedAt: new Date(),
	seo: null,
	content: [],
	analyticId: null,
	bottomPadding: true,
};

export const fetchModularPageById = async (id: number): Promise<IModularPage> => {
	const url = Endpoints.getModularPageById(id);
	const pagesData = await fetchData(url, strapiOptions);

	return pagesData;
};
