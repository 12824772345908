import { fetchGameFeed, fetchGamePageConfig } from '@/libs/api/game';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { TSeoType } from '@/libs/api/seo';
import { THexColor } from '@/types/types';
import { CTA_MESSAGE_MAX_LENGTH } from '@/features/preroll/model';
import { GameModelFromFeed, ShortGameModelFromFeed } from './types';
import { $arenaDataStore } from '@/app.model';

export type TGameData = GameModelFromFeed | null | undefined;

export type TGameButton = { [key: string]: string };

export type TGameStart = {
	button: any;
	message: string | null;
	backgroundImage: { url: string } | null;
	buttonText: string | null;
	buttonLink: string | null;
	backgroundColor: THexColor | null;
	backgroundColor_end: THexColor | null;
};

export type TGameEnd = TGameStart & { finalScoreText: string };
export type TAdblockerDetectorConfig = TGameStart & {};

export type ConfigGamePage = {
	id: number;
	adsFree: boolean;
	openWeb: boolean;
	bottomRail: boolean | null;
	leftRail: boolean | null;
	rightRail: boolean | null;
	preroll: boolean | null;
	prerollSeconds: string | null; //`s-${number}` format due to CMS enum limitations
	prerollShowRate: number | null;
	adPodding: boolean | null;
	seo: TSeoType | null;
	gameStartConfig: TGameStart | null;
	gameEndConfig: TGameEnd | null;
	adblockerDetectorConfig?: TAdblockerDetectorConfig | null;
	recommendedSectionCopy: string | null;
	shouldUseGameTheme: boolean | null;
};

export type TSearchParams = Record<string, string | undefined>;

export const initGamePageConfig: ConfigGamePage = {
	id: 0,
	adsFree: false,
	openWeb: true,
	bottomRail: true,
	leftRail: true,
	rightRail: true,
	preroll: true,
	prerollSeconds: 's-30',
	prerollShowRate: 1,
	adPodding: true,
	seo: null,
	gameStartConfig: null,
	gameEndConfig: null,
	recommendedSectionCopy: null,
	shouldUseGameTheme: false,
};
export const gameFeedReceivedFx = createEffect(fetchGameFeed);
export const gamePageConfigReceivedFx = createEffect(fetchGamePageConfig);
export const gameSlugUpdated = createEvent<string>();
export const $currentGameSlug = createStore<string | null>(null, {
	name: 'currentGameSlug',
});
export const $gameFeed = createStore<ShortGameModelFromFeed[]>([], {
	name: 'gameFeed',
});
export const $gameData = createStore<GameModelFromFeed | null>(null, {
	name: 'gameData',
});
export const $gameRenderingUrl = createStore<string | null>(null, {
	name: 'gameRenderingUrl',
});

export const configGamePageUpdated = createEvent<ConfigGamePage>();
export const $configGamePageStore = createStore<ConfigGamePage | null>(null, {
	name: 'configGamePage',
});

sample({
	clock: gamePageConfigReceivedFx.doneData,
	fn: (config) => {
		return {
			...config,
			prerollSeconds: config.prerollSeconds ? config.prerollSeconds.split('-')[1] : null,
		};
	},
	target: configGamePageUpdated,
});

sample({
	clock: gameSlugUpdated,
	target: $currentGameSlug,
});

sample({
	clock: gameFeedReceivedFx.doneData,
	fn: (feed) =>
		feed.map(({ slug, name, meta }) => ({
			slug,
			name,
			meta: {
				name: meta?.name,
				alias: meta?.alias,
				details: meta?.details,
				categories: meta?.categories,
				tags: meta?.tags,
				badges: meta?.badges,
				thumbs: {
					graphic_56x56: meta?.thumbs?.graphic_56x56,
					graphic_288x192: meta?.thumbs?.graphic_288x192,
				},
			},
		})),
	target: $gameFeed,
});

sample({
	clock: gameFeedReceivedFx.doneData,
	source: {
		$currentGameSlug,
		$arenaDataStore,
	},
	fn: ({ $currentGameSlug: slug, $arenaDataStore: arenaData }, feed) => {
		const game = feed?.find((game) => game.meta.alias === slug || game.slug === slug) ?? null;
		const arenaCategories = arenaData?.arena_categories?.map((c) => c?.slug?.trim().toLocaleLowerCase()) ?? [];
		const arenaTags = arenaData?.arena_tags?.map((t) => t?.name?.trim().toLocaleLowerCase()) ?? [];

		if (!game) {
			return null;
		}

		const gameWithOnlyAvailableCategories = {
			...game,
			meta: {
				...game?.meta,
				categories: game?.meta?.categories
					?.map?.((category) => category.trim().toLocaleLowerCase())
					?.filter?.((category) => arenaCategories.includes(category)),
			},
		};

		return gameWithOnlyAvailableCategories;
	},
	target: $gameData,
});

sample({
	clock: configGamePageUpdated,
	fn: (data) => {
		if (!data) {
			return initGamePageConfig;
		}
		if (data.adsFree) {
			return {
				...data,
				bottomRail: false,
				leftRail: false,
				rightRail: false,
				adPodding: false,
			};
		}
		data.rightRail ?? (data.rightRail = true);
		data.leftRail ?? (data.leftRail = true);
		data.bottomRail ?? (data.bottomRail = true);
		data.adPodding ?? (data.adPodding = true);
		data.gameEndConfig?.message ?? '';
		data.gameEndConfig?.buttonText ?? '';
		data.gameEndConfig?.finalScoreText ?? 'Your final score:';
		data.gameEndConfig?.backgroundColor ?? '';
		data.gameEndConfig?.backgroundColor_end ?? '';
		data.gameEndConfig?.backgroundImage?.url ?? '';
		return data;
	},
	target: $configGamePageStore,
});

const INIT_MESSAGE_TEXT = 'You broke your own high score, way to go!';
export const $gameEndMessageText = createStore<string>(INIT_MESSAGE_TEXT);

sample({
	clock: configGamePageUpdated,
	fn: (data) => {
		return data.gameEndConfig?.message?.slice(0, CTA_MESSAGE_MAX_LENGTH) ?? INIT_MESSAGE_TEXT;
	},
	target: $gameEndMessageText,
});

const INIT_FINAL_SCORE_TEXT = 'Final score';
export const $gameEndFinalScoreText = createStore<string>(INIT_FINAL_SCORE_TEXT);

sample({
	clock: configGamePageUpdated,
	fn: (data) => {
		return data.gameEndConfig?.finalScoreText?.slice(0, 16) ?? INIT_FINAL_SCORE_TEXT;
	},
	target: $gameEndFinalScoreText,
});
