import { UIKIT_CONSTS } from '@/components/UiKit/UiKitConnector';

const NextDataCacheTTL = process.env.BUILD_ENV === 'production' ? 600 : 0;

const TIMEOUT = 10000;

const createFetchOptions = (headers?: Headers): RequestInit => {
	const options: RequestInit = {
		next: { revalidate: NextDataCacheTTL },
		headers: new Headers(),
	};

	if (headers) {
		headers.forEach((value, key) => {
			(options.headers as Headers).set(key, value);
		});
	}

	return options;
};

export const strapiOptions = createFetchOptions(
	new Headers({
		'Authorization': `Bearer ${process.env.STRAPI_API_KEY}`,
		'Cache-Control': process.env.BUILD_ENV === 'production' ? 'public, max-age=900, immutable' : 'no-cache',
	}),
);

export const gameOptions = createFetchOptions(
	new Headers({
		'api-key': process.env.GAME_API_KEY,
		'Cache-Control': process.env.BUILD_ENV === 'production' ? 'public, max-age=900, immutable' : 'no-cache',
	}),
);

export const geoServiceOptions = {
	...createFetchOptions(
		new Headers({
			'X-Geo-App': 'Arena5',
		}),
	),
	cache: 'no-store',
};

export const fetchData = async (url: string, options = {}) => {
	try {
		const controller = new AbortController();
		const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);

		const response = await fetch(url, {
			...options,
			signal: controller.signal,
		});
		clearTimeout(timeoutId);

		if (response.status === 204) {
			throw new Error(`No content for this url: ${url}`);
		}

		if (response.ok) {
			if (response.body) {
				const data = await response.json();
				return data;
			}
		}

		if (response.status === 401) {
			throw new Error('Unauthorized, please check your API key');
		}

		if (response.status === 404) {
			throw new Error(`Resource not found: ${url}`);
		}

		console.error(`Failed to fetch data from: ${url}. Status: ${response.status}`);
		throw new Error(`HTTP error: ${response.status}`);
	} catch (err: unknown) {
		if (err instanceof Error) {
			if (err.name === 'AbortError') {
				console.error(`Request timed out for: ${url}`);
				throw new Error(`Request timed out for: ${url}`);
			} else {
				console.error(`Error fetching data from ${url}: ${err.message}`);
				throw err;
			}
		} else {
			console.error(`Unknown error occurred while fetching data from ${url}`);
			throw new Error(`Unknown error occurred while fetching data from ${url}`);
		}
	}
};

const base = process.env.STRAPI_URL;

export const Endpoints = {
	getArenaData: (domain: string) => `${base}/api/arena/${encodeURIComponent(domain)}`,
	getArenaLocales: (domain: string) => `${base}/api/arena/${encodeURIComponent(domain)}/locales`,
	getArenaDataByQuery: (query: string) => `${base}/api/arenas?${query}`,
	getModularPagesByQuery: (query: string) => `${base}/api/modular-page-groups?${query}`,
	getModularPageById: (id: number) => `${base}/api/modular-page/${id}`,
	getCarousels: () => `${base}/api/carousels`,
	getCarouselById: (id: number) => `${base}/api/carousels/${id}`,
	getGameFeed: (feedId: number, lang: string = 'en') =>
		`${process.env.GAME_API_URL}/feeds/${feedId}/games?locale=${lang}`,
	getGamePageConfig: (id: number) => `${base}/api/game-page/${id}`,
	getGameBySlug: (slug: string) => `${process.env.GAME_API_URL}/games/?slug=${slug}`,
	getGameById: (id: number) => `${process.env.GAME_API_URL}/games/${id}/`,
	getAssetOriginUrl: (sdkName: string, version: string) => `${process.env.PATH_TO_GAMES_URL}${sdkName}/${version}/`,
	getGameAssetsUrl: (slug: string, path: string) => `${process.env.GAME_ASSETS_URL}${slug}/Game_Tiles/webp/${path}`,
	getSitemap: (feedId: number) => `${process.env.GAME_API_URL}/feeds/${feedId}/sitemap`,
	getUiKitSsrScript: async (version: string) => await generateUiKitUrlUsed(UIKIT_CONSTS.urlSsrFileName),
};

export const getSitemapData = async (feedId: number | null) => {
	if (!feedId) {
		console.error('getSitemapData: feedId is null');
		return [];
	}
	const sitemap = Endpoints.getSitemap(feedId);
	try {
		const sitemapData = await fetchData(sitemap, gameOptions);
		return sitemapData;
	} catch (error) {
		console.error(`Error fetching sitemap data for feedId ${feedId}:`, error);
		return [];
	}
};

export async function generateUiKitUrlUsed(fileName: string): Promise<string> {
	const isDev = !!process.env.UI_KIT_URL_BASE_DEVELOPMENT; // UI_KIT_URL_BASE_DEVELOPMENT is empty for prod
	const uiKitVersion = process.env.NEXT_PUBLIC_UIKIT_VERSION;
	const prodUrl = `${UIKIT_CONSTS.urlBase}/${uiKitVersion}/${fileName}`;
	if (!isDev) return prodUrl;

	const path = process.env.UI_KIT_URL_BASE_DEVELOPMENT; //
	const version = UIKIT_CONSTS.urlVersionDefault || 'nightly';
	const filename = fileName;
	const localUrl = `${path}/${version}/${filename}`;
	try {
		const localRes = await fetch(localUrl);
		const localResStatusOk = localRes?.status === 200;
		return localResStatusOk ? localUrl : prodUrl;
	} catch (error) {
		console.error('UIKIT local URL not found, Defaulting to prod URL', error);
		return prodUrl;
	}
}
